const month = ['Januari', 'Pebruari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

export const dateYear = (value) => {
    value = value?.split("-");
    return `${value?.[2] ?? '-'}`;
}

export const dateFormatter = (value) => {
    const date = new Date(value);
    return `${setZero(date.getDate())} ${month[date.getMonth()]} ${date.getFullYear()}`;
}

export const dateFormatterV2 = (value) => {
    const date = new Date(value);
    return `${setZero(date.getFullYear())}-${date.getMonth() + 1}-${date.getDate()}`;
}

export const dateFormatterV3 = (value) => {
    const date = new Date(value);
    // Get the components of the date
    let year = date.getUTCFullYear();
    let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    let day = date.getUTCDate().toString().padStart(2, '0');
    let hours = date.getUTCHours().toString().padStart(2, '0');
    let minutes = date.getUTCMinutes().toString().padStart(2, '0');
    let seconds = date.getUTCSeconds().toString().padStart(2, '0');
    let milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');

    // Create the formatted date string
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}

export const dateFormatterV4 = (value) => {
    if (value) {
        const date = new Date(value);
        return value === undefined ? '' : `${setZero(date.getDate())}-${setZero(date.getMonth() + 1)}-${date.getFullYear()}`;
    } else {
        return null;
    }
}

export const dateFormatterV5 = (value) => {
    const date = new Date(value);
    return value === undefined ? '' : `${date.getFullYear()}-${setZero(date.getMonth())}-${setZero(date.getDate())}`;
}

export const dateFormatterV6 = (value) => {
    if (value) {
        value = value.split("-");
        value = `${value[2]}-${value[1]}-${value[0]}`;
        const date = new Date(value);
        return value === undefined ? '' : `${date.getFullYear()}-${setZero(date.getMonth() + 1)}-${setZero(date.getDate())}`;
    } else {
        return null;
    }
}

const setZero = (value) => {
    return value < 10 ? `0${value}` : value;
}